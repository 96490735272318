import React from "react";
import { Drop, Footer, Seo } from "../components";
import {
	AboutUs,
	Contact,
	ResidentialTintService,
	ResidentialWindowTintingService,
	Testimonial,
	WhyUs,
} from "../containers";

const ResidentialWindowTinting = () => {
	return (
		<div className='w-screen'>
			<Seo
				title='Professional Residential Window Tinting Services | Quality Results'
				description='Get the perfect look and long-term protection for your home with residential window tinting. Our professional installers ensure a quality job and provide a variety of options to meet your needs. Enjoy the benefits of reduced glare, UV protection, and energy savings'
			/>
			<ResidentialWindowTintingService />
			<Testimonial />
			<WhyUs />
			<ResidentialTintService />
			<Contact />
			<AboutUs />
			<Drop />
			<Footer />
		</div>
	);
};

export default ResidentialWindowTinting;
